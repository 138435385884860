import { FunctionComponent, useEffect, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { oneLine } from 'common-tags';

import * as childs from './childs';
import { Bag, User } from '@/icons';
import { Container, MenuIcon } from '@/atoms';
import { SocialMedia } from '@/molecules';

import { pickChild, pickChilds } from '@/lib/utils';
import { routes } from '@/lib/routes';

import { TypeHeaderProps } from './types';

import { useSelector } from 'react-redux';
import { useRouteChangeStart } from '@/hooks';
import { RootState } from '@/lib/store';
import { DrupalBlock } from 'next-drupal';


export interface HeaderProps {
  menus: Record<string, never>,
  blocks: {
    headerSettings: DrupalBlock,
  }
}


/**
 * Header
 */
export const Header: FunctionComponent<TypeHeaderProps> = ({
  className = '',
  children,
  socialMedias,
  logo,
  theme = 'light',
  ...props
}: TypeHeaderProps): JSX.Element => {
  const [, menu] = pickChild(children, childs.MenuAtom);
  const [, buttonMobile] = pickChilds(children, childs.ButtonMobile);
  const [, sidebar] = pickChilds(children, childs.Sidebar);
  const [isOpen, setOpen] = useState(false);
  const carts = useSelector((state: RootState) => state.carts);
  const [classCounter, setClassCounter] = useState('scale-100');
  useEffect(() => {
    if (carts.data.cart?.lines.edges.length > 0) {
      setClassCounter('scale-150');
      setTimeout(() => setClassCounter('scale-100'), 250);
    }
  }, [carts.data.cart?.lines.edges]);

  useRouteChangeStart(() => setOpen(false));

  return (
    <Container
      as="header"
      padding="px-4 py-3 lg:py-5"
      className={oneLine`
        ${isOpen
          ? 'bg-white text-text-primary !fixed top-0 left-0 right-0 lg:bg-transparent lg:!relative border-b border-secondary-200 lg:border-none'
          : theme === 'dark'
            ? 'text-white'
            : 'text-text-primary'
        }
        h-[var(--header-height)] relative z-10 
        lg:[&:has([data-menu]:hover)]:bg-white lg:[&:has([data-menu]:hover)]:text-text-primary
        [&:has([data-submenu-open="true"])_[data-button]]:hidden
        ${className}
      `}
      backgroundColor=""
      {...props}
    >
      <div className="m-auto lg:mx-auto max-w-[var(--wrapper-size)] w-full">
        <div className="hidden lg:flex justify-end gap-8 text-body text-body--s">
          {sidebar}
        </div>

        <div className="flex justify-between items-center lg:items-end">
          <div className="relative h-16 w-16 lg:w-14 lg:h-16">
            <Link href={routes.home} prefetch={false} aria-label="Inicio">
              <Image
                src={logo.src}
                alt={logo.alt ?? ''}
                className="w-full h-full object-contain object-center"
                draggable="false"
                priority
                sizes="100vw"
                fill
              />
            </Link>
          </div>

          <div
            className={oneLine`
              ${isOpen
                ? 'flex left-0 w-full lg:static'
                : 'hidden -left-full lg:left-0 lg:static'
              }
              fixed overflow-y-auto h-[calc(100%_-_var(--header-height))] lg:overflow-auto lg:h-auto top-[var(--header-height)] bg-white text-text-primary lg:text-inherit lg:bg-transparent pt-4 lg:pt-0 px-4 lg:px-0 lg:static lg:flex transition duration-500
            `}
          >
            <div className="w-full flex flex-col">
              <div className="relative lg:static mb-8 lg:mb-0 flex-grow">
                {menu}
              </div>

              {buttonMobile}

              <SocialMedia
                socialMedias={socialMedias}
                className="lg:hidden mb-6 justify-center flex relative"
              />

              <hr className="lg:hidden text-secondary-200" />

              <div className="lg:hidden mt-6 pb-8 flex justify-around font-bold text-primary-500">
                {sidebar}
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center gap-4">
            <Link
              href="https://account.navidul.es"
              className={!isOpen ? 'block' : 'hidden'}
            >
              <User className="lg:hidden" width={32} height={32} />
            </Link>
            <Link
              href="/es/cesta"
              className={`${!isOpen ? 'flex' : 'hidden '} lg:flex relative`}
            >
              <Bag width={32} height={32} className='header-bag' />

              <span
                className={`absolute ${classCounter} transition-transform -right-1 -bottom-1 h-4 w-4 text-caption text-caption--m bg-tertiary-500 text-text-alternative rounded-full flex justify-center items-center`}
              >
                {carts.data.cart?.lines.edges.length > 0
                  ? carts.data.cart.lines.edges.reduce(
                    (partialSum: number, a: any) =>
                      partialSum + a.node.quantity,
                    0
                  )
                  : '0'}
              </span>
            </Link>

            <MenuIcon
              open={isOpen}
              onClick={() => setOpen((isOpen) => !isOpen)}
              className="lg:hidden"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

Header.displayName = 'DesktopHeader';
